<template>
	<div id="PasswordReset" class="input-heading">
		<form class="input-section" :class="countryCode === 'MX' ? 'encore-background' : ''" v-if="!codeSent">
			<h3>{{ languageStrings?.resetPassword }}</h3>
			<label for="phoneNumber">{{ languageStrings?.playersPhoneNumber }}</label>
			<!-- need "v-on:keydown.enter.prevent" to stop ENTER key from attempting submit. Tries to submit to "/?phoneNumber=" -->
			<input
				type="tel"
				id="phoneNumber"
				:name="languageStrings?.playersPhoneNumber"
				@keydown.enter="requestChangePasswordCode()"
				v-model="phoneNumber"
				:placeholder="languageStrings?.playersPhoneNumber"
				autocomplete="phoneNumber"
				v-on:keydown.enter.prevent
				:maxlength="phoneNumberLength"
			/>
			<span v-if="!captchaResponse && captchaMessage" style="color: #ff0; text-align: center; font-weight: 700">{{ captchaMessage }}</span>
			<Captcha v-if="serverRules?.captchaType !== 'None'" :serverRules="serverRules" :isMobile="isMobile" :languageStrings="languageStrings" />
			<div class="button-container">
				<button class="btn" type="button" :title="buttonLabel" @click="requestChangePasswordCode()">{{ buttonLabel }}</button>
				<button type="button" :title="languageStrings?.back" class="back btn" @click="back()">{{ languageStrings?.back }}</button>
			</div>
			<small style="text-align: center">{{ languageStrings?.smsDisclaimer }}</small>
		</form>

		<form class="input-section" v-if="codeSent">
			<label for="confirmation">{{ languageStrings?.confirmationCode }}</label>
			<input
				type="text"
				id="confirmation"
				:name="languageStrings?.confirmationCode"
				maxlength="6"
				pattern="\d{6}"
				@keydown.enter="changeLostPassword()"
				v-model="confirmationCode"
				:placeholder="languageStrings?.confirmationCode"
				autocomplete="off"
				onfocus="this.value=''"
			/>
			<label for="newPassword">{{ languageStrings?.newPassword }}</label>
			<div class="password-container">
				<input
					:type="unhidePassword ? 'text' : 'password'"
					id="newPassword"
					:name="languageStrings?.newPassword"
					v-model="newPassword"
					:placeholder="languageStrings?.newPassword"
					:minlength="minimumLength"
					:maxlength="serverRules?.passwordRules?.maximumLength"
					:pattern="`.{${serverRules?.passwordRules?.minimumLength},}`"
					autocomplete="new-password"
				/>
				<span
					@click="unhidePassword = unhidePassword ? false : true"
					:class="unhidePassword ? 'hidden' : ''"
					:title="unhidePassword ? languageStrings.hidePassword : languageStrings.showPassword"
				>
					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="eye" viewBox="0 0 16 16">
						<path
							d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"
						/>
						<path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
					</svg>
				</span>
			</div>

			<label for="confirmPassword">{{ languageStrings?.confirmPassword }}</label>
			<div class="password-container">
				<input
					:type="unhideConfirm ? 'text' : 'password'"
					id="confirmPassword"
					:name="languageStrings?.confirmPassword"
					@keydown.enter="changeLostPassword()"
					v-model="confirmPassword"
					:placeholder="languageStrings?.confirmPassword"
					autocomplete="off"
					:minlength="serverRules?.passwordRules?.minimumLength"
					:maxlength="serverRules?.passwordRules?.maximumLength"
					:pattern="`.{${serverRules?.passwordRules?.minimumLength},}`"
				/>
				<span
					@click="unhideConfirm = unhideConfirm ? false : true"
					:class="unhideConfirm ? 'hidden' : ''"
					:title="unhideConfirm ? languageStrings.hidePassword : languageStrings.showPassword"
				>
					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="eye" viewBox="0 0 16 16">
						<path
							d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"
						/>
						<path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
					</svg>
				</span>
			</div>

			<span v-if="capsLockOn" style="color: #ff0; text-align: center; font-weight: 700">{{ languageStrings.capsLockOn }}</span>
			<div class="button-container">
				<button v-if="showSMSButton" class="btn" type="button" :title="languageStrings?.resendCode" @click="requestChangePasswordCode()">
					{{ languageStrings.resendCode }}
				</button>
				<button type="button" class="btn" :title="languageStrings?.changePassword" @click="changeLostPassword()">
					{{ languageStrings.changePassword }}
				</button>
				<button type="button" class="btn" :title="languageStrings?.cancel" @click="eventBus.emit('returnFromResetPW')">
					{{ languageStrings?.cancel }}
				</button>
			</div>
			<small style="text-align: center"
				>{{ languageStrings.passwordRequirements1 }} {{ serverRules?.passwordRules?.minimumLength }}
				{{ languageStrings.passwordRequirements2 }}
				<span v-if="serverRules?.passwordRules?.minimumNumberOfCharacterClasses > 0">
					{{ languageStrings.passwordRequirements3 }} {{ serverRules?.passwordRules?.minimumNumberOfCharacterClasses }}
					{{ languageStrings.passwordRequirements4 }}
				</span>
			</small>
			<div id="pw-strength-container">
				<p>
					{{ languageStrings?.passwordStrength }}:
					<span v-if="newPassword?.length > 0 && strengthCheck =='weak'" class="score-words" :class="strengthCheck">{{ languageStrings.weak }}</span>
					<span v-if="newPassword?.length > 0 && strengthCheck =='medium'" class="score-words" :class="strengthCheck">{{ languageStrings.medium }}</span>
					<span v-if="newPassword?.length > 0 && strengthCheck =='strong'" class="score-words" :class="strengthCheck">{{ languageStrings.strong }}</span>
				</p>
				<progress id="pw-strength" class="custom-bar" :class="strengthCheck" :value="pwStrength" max="100">
					{{ languageStrings?.passwordStrength }}
				</progress>
			</div>
		</form>
	</div>
</template>

<script>
// import store from "@/store/index";
import router from "@/router";
import { onBeforeUnmount } from "vue";
import sharedScripts from "@/dependencies/sharedScripts";
import Captcha from "@/components/Captcha";

export default {
	name: "PasswordReset",
	components: {
		Captcha,
	},
	props: {
		languageStrings: Object,
		languageErrorStrings: Object,
		playerState: Object,
		capsLockOn: Boolean,
		serverRules: Object,
		isMobile: Boolean,
	},
	data() {
		return {
			status: Object.assign({}, this.globalStatus),
			unhidePassword: false,
			unhideConfirm: false,
			phoneNumber: "",
			newPassword: "",
			confirmPassword: "",
			codeSent: false,
			resendCode: false,
			confirmationCode: "",
			buttonLabel: this.languageStrings?.getChangeCode || "",
			matchedCharClasses: {},
			validNumberCharClasses: false,
			pwStrength: 0,
			strengthCheck: "weak",
			// minimumNumberOfCharacterClasses: this.serverRules.passwordRules?.minimumNumberOfCharacterClasses,
			// minimumLength: this.serverRules.passwordRules?.minimumLength,
			// maximumLength: this.serverRules.passwordRules?.maximumLength,
			// inputPattern: `.{${this.serverRules.passwordRules?.minimumLength},}`,
			captchaResponse: "",
			captchaMessage: "",
			showSMSButton: true,
		};
	},
	watch: {
		newPassword() {
			this.passwordStrengthCheck();
		},
		confirmPassword() {
			this.passwordStrengthCheck();
		},
		serverRules() {
			coonsole.log("test serverRules");
		},
	},
	created() {
		this.eventBus.on("captchaSuccess", (payload) => {
			this.captchaResponse = payload;
		});
		onBeforeUnmount(() => {
			this.eventBus.off("captchaSuccess");
		});
	},
	mounted() {
		this.eventBus.emit("initializeCaptcha");
	},
	methods: {
		passwordStrengthCheck() {
			this.pwStrength = this.scorePassword(this.newPassword);

			if (this.pwStrength <= 33) this.strengthCheck = "weak";
			if (this.pwStrength > 33 && this.pwStrength < 66) this.strengthCheck = "medium";
			if (this.pwStrength >= 66) this.strengthCheck = "strong";

			if (this.newPassword === this.confirmPassword && this.newPassword.length >= this.serverRules.passwordRules?.minimumLength) {
				this.matchedCharClasses = this.characterClassCheck(this.newPassword, this.serverRules.passwordRules?.minimumNumberOfCharacterClasses);
				this.validNumberCharClasses = this.matchedCharClasses.characterClassesValid;
			}
		},
		async requestChangePasswordCode() {
			this.codeSent = false;
			this.confirmationCode = "";

			let body = {
				PhoneNumber: this.phoneNumber.toString(),
				CaptchaResponse: this.captchaResponse,
			};

			if (!this.phoneNumber) {
				this.status.message = this.languageErrorStrings.provideValidPhone;
				this.status.ok = false;
				this.eventBus.emit("updateStatus", this.status);
				return false;
			}

			if (!this.serverRules?.captchaType === "None" && !this.captchaResponse) {
				this.captchaMessage = this.languageErrorStrings.challengeNotSolved;
				hasErrors = true;
			}

			let requestUrl = new URL("/api/v1/user/password/lost/code", this.rabbitsfootHostUrl);
			let headerObj = new Headers();
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let request = new Request(requestUrl.toString(), {
				method: "POST",
				body: JSON.stringify(body),
				headers: headerObj,
			});

			try {
				const response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response, this.languageErrorStrings);

				if (fetchStatus && !fetchStatus.ok) {
					fetchStatus.message = fetchStatus.code === 404 ? this.languageErrorStrings.phoneNotFound : fetchStatus.message;
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout === true) this.eventBus.emit("forceLogout");
					return;
				}

				let dataJson = await response.json();

				if (fetchStatus.ok) this.status = sharedScripts.checkSuccessErrors(dataJson.status, this.languageErrorStrings);
				if (dataJson?.status === "UserAccountIsLocked") this.eventBus.emit("returnFromResetPW");
				this.codeSent = this.status.ok;
				this.buttonLabel = this.languageStrings.sendNewCode;

				this.smsButtonTimeout();

				this.eventBus.emit("updateStatus", this.status);
				// this.eventBus.emit("updatePlayerState", dataJson);
			} catch (e) {
				console.error(e);
			}
		},
		async changeLostPassword() {
			let hasErrors = false;

			if (!this.newPassword || !this.confirmPassword) {
				this.status.ok = false;
				this.status.message = this.languageErrorStrings.passwordFieldsEmpty;
				this.eventBus.emit("updateStatus", this.status);
				hasErrors = true;
			}

			if (this.newPassword !== this.confirmPassword) {
				this.status.ok = false;
				this.status.message = this.languageErrorStrings.passwordDoNotMatch;
				this.eventBus.emit("updateStatus", this.status);
				hasErrors = true;
			}

			if (!this.confirmationCode) {
				this.status.message = this.languageErrorStrings.provideCode;
				this.status.ok = false;
				this.eventBus.emit("updateStatus", this.status);
				hasErrors = true;
			}

			if (this.newPassword?.length < this.serverRules.passwordRules?.minimumLength) {
				this.status.ok = false;
				this.status.message = `${this.languageErrorStrings.passwordMustHaveAtLeast} ${this.serverRules.passwordRules?.minimumLength} ${this.languageErrorStrings.characters}`;
				this.eventBus.emit("updateStatus", this.status);
				hasErrors = true;
			}

			if (this.newPassword?.length > 0 && !this.validNumberCharClasses) {
				this.status.ok = false;
				this.status.message = `${this.languageErrorStrings.passwordMustHaveAtLeast} ${this.serverRules.passwordRules?.minimumNumberOfCharacterClasses} ${this.languageErrorStrings.characterClassesError}`;
				this.eventBus.emit("updateStatus", this.status);
				hasErrors = true;
			}

			if (hasErrors === true) return;

			let body = {
				PhoneNumber: this.phoneNumber.toString(),
				Code: this.confirmationCode,
				NewPassword: this.newPassword,
			};

			let requestUrl = new URL("/api/v1/user/password/lost", this.rabbitsfootHostUrl);
			let headerObj = new Headers();
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let request = new Request(requestUrl.toString(), {
				method: "POST",
				body: JSON.stringify(body),
				headers: headerObj,
			});

			try {
				const response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response, this.languageErrorStrings);

				if (fetchStatus && !fetchStatus.ok) {
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout === true) this.eventBus.emit("forceLogout");
					return;
				}

				let dataJson = await response.json();

				if (fetchStatus.ok) this.status = sharedScripts.checkSuccessErrors(dataJson.status, this.languageErrorStrings);

				let playerState = {};
				if (this.status.message === "Success") {
					this.status.ok = true;
					this.status.message = this.languageErrorStrings.passwordChangeSuccessfully;
					this.eventBus.emit("updateStatus", this.status);
					this.eventBus.emit("returnFromResetPW");
					this.eventBus.emit("updatePlayerState", playerState);
					router.push("/");
				} else {
					this.eventBus.emit("updateStatus", this.status);
				}
			} catch (e) {
				console.error(e);
			}
		},
		smsButtonTimeout() {
			this.showSMSButton = false;
			setTimeout(() => {
				this.showSMSButton = true;
			}, 60000);
		},
		back() {
			this.eventBus.emit("returnFromResetPW");
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
	text-align: center;
}
#PasswordReset {
	position: fixed;
	display: grid;
	align-items: center;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 10001;
	background: rgba(0, 0, 0, 0.6);
	backdrop-filter: blur(4px);
	-webkit-backdrop-filter: blur(4px);
	overflow: hidden auto;
}

#PasswordReset form {
	margin: 15px auto;
	width: 75vw;
}

.input-section {
	display: flex;
	flex-direction: column;
	width: 90%;
	margin: 30px auto;
	background-color: #24272c;
	padding: 30px;
	border-radius: 8px;
	box-shadow: 2px 3px 8px rgb(0 0 0 / 75%), inset 4px 3px 10px 4px rgb(42 63 88 / 30%), inset -2px -2px 10px 2px rgb(0 0 0 / 30%);
}

.button-container {
	display: flex;
}

.btn {
    width: 8em;
}

.back {
	/* background: #efefef; */
	/* padding: 5px 10px; */
	/* margin: auto auto auto 0; */
	text-decoration: none;
	/* position: absolute; */
}

@media (min-width: 768px) {
	.input-section {
		width: 50%;
	}

	#PasswordReset form {
		width: 50vw;
	}
}
</style>
